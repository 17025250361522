<template>
  <div class="next-button mt-2">
    <button @click="$emit('previous')" class="gg_question_check mr-1">
      {{ prevtitle }}
    </button>

    <button class="gg_question_check" @click="$emit('next')" :disabled="disabled">
      {{ nexttitle
      }}<span
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="#00f700"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M5 13l4 4L19 7"
          /></svg
      ></span>
    </button>
  </div>
</template>

<script>
export default {
  name: "Buttons",
  props: {
    prevtitle: String,
    nexttitle: String,
    disabled: Boolean
  },
};
</script>
