<template>
    <div 
    class="infobox" 
    >
        {{ infotext }}
        
    </div>
</template>

<script>
    export default {
        name: 'Infobox',
        props: {
            infotext: String,
            show: Boolean
        }
    }
</script>