<template>
  <div class="gg_res_row" v-if="price > 0">
    <div class="gg_res_left"> <b>{{ title }}</b></div>
    <div class="gg_res_right">{{ toCurrency(price) }}</div>
  </div>
</template>

<script>

export default {
  name: "ResultTable",
  props: {
    title: String,
    price: Number
  },
  methods:{
    toCurrency(value){
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR'
      });
      return formatter.format(value);
    }
  }
};
</script>
