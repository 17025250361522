<template>
  <input
      id="searchInputAdd"
      ref="location_autocomplete"
      type="text"
      size="50"
  />
</template>

<script>
import {Loader} from '@googlemaps/js-api-loader'

export default {
  name: "GoogleMapLoader",
  components: {},
  emits: ["place_changed"],
  data() {
    return {
      loader: null,
      google: null,
    }
  },
  async mounted(){
    let container = this.$refs.location_autocomplete;

    this.loader = new Loader({
      apiKey: "AIzaSyBg_RFSUJgvtBk2QzZuBUJT2M9lk38ebjk",
      version: "weekly",
      libraries: ["places","geometry"]
    })

    let vm = this;

    this.loader
        .load()
        .then((google) => {
              let options = {
                types: ['geocode', 'establishment'],
                componentRestrictions: {
                  country: "DE",
                },
                fields: ['place_id', 'geometry', 'name']
              };
              let autocomplete = new google.maps.places.Autocomplete(container, options);

              google.maps.event.addListener(autocomplete, 'place_changed', function () {
                let place = autocomplete.getPlace();
                let lat = place.geometry.location.lat(),
                    lng = place.geometry.location.lng();
                console.log("https://www.google.com/maps/search/?api=1&query=" + lat + "%2C" + lng + "&query_place_id=" + place.place_id)
                let pos =  {lat: lat, lng: lng}
                if (typeof place === "undefined") {
                  console.log("Not found!");
                }
                else {
                  vm.$emit('place_changed', pos, place)
                }

              });
              // Set initial restriction to the greater list of countries.
              autocomplete.setComponentRestrictions({
                country: ["DE", "AT", "CH"],
              });
            }
        )
  }
}
</script>
