<template>
  <div>
    <transition name="fade" mode="out-in"
                @before-leave="scrollUp">
      <div
          id="gg_configurator"
          class="gg_configurator"
          :class="current == 0 ? 'map_configurator' : ''"
          v-if="current == 0"
          key="step_0"
          :style="{'justifyContent': adresse !== '' ? 'left' : 'center'}"
      >
        <div id="gg_question_container" class="with-map"
        >
          <div data-state="0" class="gg_question_box">
            <h2>{{ this.sources[0].title }}</h2>
            <Question :title="this.sources[0].question"/>
            <GoogleAutosuggest @place_changed="changePlace"></GoogleAutosuggest>


            <div class="gg_map_area">
              <p>
                Aktuelle Fläche: <b>{{ parseFloat(area).toFixed(2).replace(".", ",") + ' ' }}</b> m&sup2;
                <!-- m&sup2 -->
              </p>
            </div>

            <div id="imageselect" :class="area == 0 ? 'disabled' : ''">
              <Imageselect
                  @input="status = $event.target.value; image_select(1)"
                  type="radio"
                  v-model="status"
                  :value="'komplettsanierung'"
                  image="images/komplettsanierung.svg"
                  title="Komplett- &nbsp; sanierung"
                  id="scope-1"
                  :disabled="area == 0"
              />

              <Imageselect
                  @input="status = $event.target.value; image_select(1)"
                  type="radio"
                  v-model="status"
                  value="teilsanierung"
                  image="images/teilsanierung.svg"
                  title="Teilsanierung"
                  id="scope-2"
                  :disabled="area == 0"
              />
              <Imageselect
                  @input="status = $event.target.value; image_select(2)"
                  type="radio"
                  v-model="status"
                  :value="'neubau'"
                  image="images/neubau.svg"
                  title="Neubau"
                  id="scope-3"
                  :disabled="area == 0"
              />
              <Imageselect
                  @input="caretacking"
                  type="radio"
                  v-model="status"
                  :value="'pflege'"
                  image="images/care.svg"
                  title="Pflege"
                  id="scope-4"
                  :disabled="area == 0"
              />
            </div>
            <hr/>
            <!-- Infoblock -->
            <div class="gg_infoblock">
              <ul>
                <li>
                  Zeichnen Sie die Fläche des Platzes durch Klicken auf dessen äußere Eckpunkte
                </li>
                <li>
                  Bewegen Sie die Karte durch gedrückt halten der Maus bzw. mit zwei Fingern (mobil)
                </li>
                <li>
                  Zoomen Sie mit dem Mausrad bzw. mit zwei Fingern (mobil)
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div id="gg_bg"
             :class="adresse == '' ? 'disabled' : ''"
             style="background-image: url('../images/floor.jpg')">
          <google-map-loader
              apiKey="AIzaSyBg_RFSUJgvtBk2QzZuBUJT2M9lk38ebjk"
              ref="map_loader"
              :zoom="zoom"
              :location="location"
              :points="polygons"
              @input="setArea"
              @zoom_changed="setZoom"
              @center_changed="setLocation"
              @add_point="addPoint"
              @update_points="updatePoints"
          />
        </div>
        <div class="mapbutton">
          <button class="gg_question_check" @click="removeLast()">
            <span
            ><svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M12 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2M3 12l6.414 6.414a2 2 0 001.414.586H19a2 2 0 002-2V7a2 2 0 00-2-2h-8.172a2 2 0 00-1.414.586L3 12z"
                /></svg></span
            >Letzten Punkt löschen
          </button>

          <button class="gg_question_check" @click="clearPoints()">
            <span
            ><svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                /></svg></span
            >Alle Punkte löschen
          </button>
        </div>
      </div>
    </transition>

    <!-- Step 1 Rückbau
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
    <transition name="fade" mode="out-in"
                @before-leave="scrollUp">
      <div
          id="gg_configurator"
          class="gg_configurator"
          v-if="current == 1"
          key="step_1"
      >
        <div id="gg_question_container" class="no-map">
          <div data-state="1" class="gg_question_box">
            <h2>{{ this.sources[1].title }}</h2>
            <Question :title="this.sources[1].question"/>

            <div class="gg_content">
              <div class="half">
                <div class="half-inner">
                  <h3>1: Baujahr</h3>
                  <p>Wann wurde der Platz gebaut?</p>
                  <div class="gg_range_slider mt-1">
                    <input
                        class="gg_rangi"
                        step="1"
                        type="range"
                        name="year"
                        min="1990"
                        max="2022"
                        v-model="baujahr"
                    />
                    <input
                        id="year"
                        class="gg_rangi_text"
                        type="number"
                        name="year"
                        min="1990"
                        max="2022"
                        v-model="baujahr"
                    />
                  </div>
                </div>
              </div>
              <div class="half">
                <div v-if="status == 'teilsanierung'">
                  <Infobox v-if="show_info" :infotext="infotext"/>
                  <a
                      @mouseover="infoEnter"
                      @mouseleave="infoLeave"
                      infotext="Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus."
                      class="gg_info_question"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                            clip-rule="evenodd"/>
                    </svg>
                  </a>
                </div>
                <div class="half-inner" :class="classDisabled">
                  <h3>2: Elastikschicht</h3>
                  <div class="art">
                    <p>Art:</p>
                    <div class="imageselect justify-left">
                      <Imageselect
                          @click="elastikschicht = $event.target.value"
                          value="0"
                          type="radio"
                          image="images/elastikschicht.svg"
                          :title="'Elastikschicht'"
                          id="layer-1"
                          name="elastic"
                          :disabled="status == 'teilsanierung'"
                      />
                      <Imageselect
                          @click="elastikschicht = $event.target.value"
                          value="1"
                          type="radio"
                          image="images/elastische_tragschicht.svg"
                          :title="'Elastische Tragschicht'"
                          id="layer-2"
                          name="elastic"
                          :disabled="status == 'teilsanierung'"
                      />
                      <Imageselect
                          @click="elastikschicht = $event.target.value"
                          value="2"
                          type="radio"
                          image="images/dunno.svg"
                          :title="'weiß nicht'"
                          id="layer-3"
                          name="elastic"
                          :disabled="status == 'teilsanierung'"
                      />
                    </div>
                  </div>
                  <div class="mt-2" v-if="elastikschicht == 2">
                    <span class="text-sm">
                      Ohne konkrete Auswahl gehen wir von einer ET 35 als Standard aus
                    </span>
                  </div>
                  <div class="staerke d-flex mt-2"
                       v-if="elastikschicht != 2">
                    <p class="d-ib mr-1">Stärke:</p>
                    <div class="gg_range_slider">
                      <input
                          oninput="this.nextElementSibling.value = this.value"
                          class="gg_rangi"
                          type="range"
                          name="staerke"
                          min="10"
                          max="35"
                          v-model.number="elastikschicht_staerke"
                          step="5"
                          :disabled="status == 'Teilsanierung'"
                      />
                      <input
                          oninput="this.previousElementSibling.value = this.value"
                          id="staerke"
                          class="gg_rangi_text"
                          type="number"
                          name="staerke"
                          min="10"
                          max="35"
                          v-model.number="elastikschicht_staerke"
                          step="5"
                          :disabled="status == 'Teilsanierung'"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="half">
                <div class="half-inner">
                  <h3>3: Belag</h3>
                  <div class="hersteller">
                    <p>Hersteller:</p>

                    <select id="hersteller" class="" name="hersteller" v-model="hersteller">

                      <option value="0">Desso</option>
                      <option value="1">Domo</option>
                      <option value="2">Edel</option>
                      <option value="3">Fieldturf</option>
                      <option value="4">Juta</option>
                      <option value="5">Mondo</option>
                      <option value="6">Polytan</option>
                      <option value="7">sonstiges</option>
                    </select>
                  </div>
                  <div class="faser mt-2">
                    <p>Fasermaterial (Länge):</p>
                    <div class="gg_range_slider">
                      <input
                          oninput="this.nextElementSibling.value = this.value"
                          class="gg_rangi"
                          type="range"
                          name="faser"
                          min="10"
                          max="40"
                          v-model.number="faser_laenge"
                          step="1"
                      />
                      <input
                          oninput="this.previousElementSibling.value = this.value"
                          id="faser"
                          class="gg_rangi_text"
                          type="number"
                          name="faser"
                          min="10"
                          max="40"
                          v-model.number="faser_laenge"
                          step="1"
                      />
                      <p>mm</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="half">
                <div class="half-inner">
                  <h3>4: Verfüllung</h3>

                  <div class="infill">
                    <div class="d-flex">
                      <p class="d-ib mr-1">Sand:</p>
                      <div class="gg_range_slider mb-2">
                        <input
                            class="gg_rangi"
                            type="range"
                            name="sand"
                            min="20"
                            max="40"
                            v-model.number="verfuellung.sand"
                            step="1"
                        />
                        <input
                            id="sand"
                            class="gg_rangi_text"
                            type="number"
                            name="sand"
                            min="20"
                            max="40"
                            v-model.number="verfuellung.sand"
                            step="1"
                        />
                        <p>kg/m²</p>
                      </div>
                    </div>
                    <div class="d-flex">
                      <select id="infills" name="infills" v-model="verfuellung.material">
                        <option value="0">EPDM</option>
                        <option value="1">TPE</option>
                        <option value="2">SBR</option>
                      </select>
                      <div class="gg_range_slider">
                        <input
                            class="gg_rangi"
                            type="range"
                            name="gummi"
                            min="2"
                            max="10"
                            v-model.number="verfuellung.materialmenge"
                            step="1"
                        />
                        <input
                            id="gummi"
                            class="gg_rangi_text"
                            type="number"
                            name="gummi"
                            min="2"
                            max="10"
                            v-model.number="verfuellung.materialmenge"
                            step="1"
                        />
                        <p>kg/m²</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Buttons
              prevtitle="zurück"
              nexttitle="weiter"
              @previous="previousStep"
              @next="nextStep"
              :disabled="false"
          />
        </div>

        <div
            id="gg_bg"
            :style="this.sources[1].background"
            class="kenny-b"
        ></div>
      </div>
    </transition>

    <!-- Step 2
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->

    <transition name="fade" mode="out-in"
                @before-leave="scrollUp">
      <div
          id="gg_configurator"
          class="gg_configurator"
          v-if="current == 2"
          key="step_2"
      >
        <div id="gg_question_container" class="no-map">
          <div data-state="2" class="gg_question_box">
            <h2>{{ this.sources[2].title }}</h2>
            <div class="gg_question"></div>
            <div class="gg_content"
                 v-if="status == 'teilsanierung'">
              Bei der Teilsanierung wird ein "Lückenfüller" genutzt, der dem vorhandenem Material gleicht.
            </div>
            <div class="gg_content"
                 v-if="status != 'teilsanierung'">
              <div class="half no-border">
                <input
                    class="checkbox-floor"
                    type="radio"
                    name="floor"
                    id="floor-1"
                    v-model="system"
                    value="1"
                    checked
                />
                <label class="for-checkbox-floor" for="floor-1">
                  <div class="half-inner">
                    <h3 class="mb-1">System 1:</h3>
                    <p>Kunstrasen, mit Sand und Kork verfüllt</p>
                    <div class="grid col-4-6">
                      <div>
                        <img src="images/edel.jpeg" width="200px" alt=""/>
                      </div>
                      <div>
                        <ul class="mt-0">
                          <li>Robustes rautenförmiges Garn</li>
                          <li>Texturiertes Monofilament</li>
                          <li>Stabilisierende Sandeinlage erforderlich</li>
                          <li>Installiert auf einem 20-mm-Stoßdämpfer</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </label>
              </div>

              <div class="half no-border">
                <input
                    class="checkbox-floor"
                    type="radio"
                    name="floor"
                    id="floor-2"
                    v-model="system"
                    value="2"
                />
                <label class="for-checkbox-floor" for="floor-2">
                  <div class="half-inner">
                    <h3 class="mb-1">System 2:</h3>
                    <p>Kunstrasen, mit Sand und Kork verfüllt</p>
                    <div class="grid col-4-6">
                      <div>
                        <img src="images/twinblade.jpeg" width="200px" alt=""/>
                      </div>
                      <div>
                        <ul class="mt-0">
                          <li>Texturierte Monofilamente</li>
                          <li>Starkes Vollgras</li>
                          <li>Mit stabilisierendem Sand verlegt</li>
                          <li>Natürliche Farbe</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </label>
              </div>
              <div class="half no-border">
                <input
                    class="checkbox-floor"
                    type="radio"
                    name="floor"
                    id="floor-3"
                    v-model="system"
                    value="3"
                />
                <label class="for-checkbox-floor" for="floor-3">
                  <div class="half-inner">
                    <h3>System 3:</h3>
                    <p>Kunstrasen nur mit Sand verfüllt</p>
                    <div class="grid col-4-6">
                      <div>
                        <img src="images/edel.jpeg" width="200px" alt=""/>
                      </div>
                      <div>
                        <ul class="mt-0">
                          <li>Rauten-/Dreiecksgarne</li>
                          <li>Texturiertes Strohgarn</li>
                          <li>Glasfaserverstärkte Unterlage</li>
                          <li>Din/EN/RAL-Zulassungen</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </label>
              </div>
              <div class="half no-border">
                <input
                    class="checkbox-floor"
                    type="radio"
                    name="floor"
                    id="floor-4"
                    v-model="system"
                    value="4"
                />
                <label class="for-checkbox-floor" for="floor-4">
                  <div class="half-inner">
                    <h3>System 4:</h3>
                    <p>Kunstrasen nur mit Sand verfüllt</p>
                    <div class="grid col-4-6">
                      <div>
                        <img src="images/edel.jpeg" width="200px" alt=""/>
                      </div>
                      <div>
                        <ul class="mt-0">
                          <li>Rauten-/Dreiecksgarne</li>
                          <li>Texturiertes Strohgarn</li>
                          <li>Glasfaserverstärkte Unterlage</li>
                          <li>Din/EN/RAL-Zulassungen</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </label>
              </div>

            </div>
            <div class="et-info mt-4 small-font"
                 v-if="status == 'komplettsanierung' && elastikschicht != 'Elastische Tragschicht' && elastikschicht_staerke != '35'">
              Da zuvor keine elastische Tragschicht der Stärke 35mm verbaut wurde, müssen 2.115€ Mehraufwand berechnet
              werden, da dies der neue Standart ist.
            </div>
            <hr class="mt-3"/>
            <h3>Zubehör</h3>

            <div class="extras_wrapper">
              <Extras v-model="linierung" :extra_name="'Linierung'" :extras="sources[2].lines"
                      class="pl-1 pr-1"/>
              <Extras v-model="goals" :extra_name="'Tore'" :extras="sources[2].goals" class="pl-1 pr-1"/>
              <Extras v-model="corner_flag" :extra_name="'Eckfahne'" :extras="sources[2].corner_flag" :model="corner_flag"
                      class="pl-1 pr-1"/>
              <Extras v-model="pflege" :extra_name="'Pflege'" :extras="sources[2].cares" :model="pflege"
                      class="pl-1 pr-1"/>
            </div>

          </div>
          <Buttons
              prevtitle="zurück"
              nexttitle="weiter"
              @previous="previousStep"
              @next="calculate"
              :disabled="false"
          />
        </div>

        <div id="gg_bg" :style="this.sources[2].background"></div>
      </div>
    </transition>

    <!-- Step 3
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->

    <transition name="fade" mode="out-in">
      <div
          id="gg_configurator"
          class="gg_configurator"
          v-if="current == 3"
          key="step_3"
      >
        <div id="gg_question_container" class="no-map">
          <div data-state="3" class="gg_question_box">
            <div class="gg_result">
              <h2>{{ this.sources[3].title }}</h2>
              <div class="gg_content_result">
                <div class="gg_contact_container w-50">
                  Geben Sie hier Ihre Kontaktdaten ein, um eine Zusammenfassung Ihrer Kostenanalyse zu erhalten. Gerne kalkulieren wir Ihnen ein individuelles Angebot nach einer Ortsbesichtigung.
                  <form action="">

                      <label v-if="status == 'pflege'" for="baujahr">Baujahr</label><br/>
                      <input v-if="status == 'pflege'" type="number" id="baujahr" name="baujahr" placeholder="Baujahr" v-model="baujahr">

                    <label for="name">Name</label>
                    <input type="text" id="name" name="name" required placeholder="Ihr Name" v-model="name">

                    <label for="position">Funktion</label>
                    <input type="text" id="position" name="position" v-model="position" placeholder="Ihre Funktion innerhalb des Vereins">

                    <label for="verein">Verein</label>
                    <input type="text" id="verein" name="verein" v-model="verein" placeholder="Name des Sportvereins">

                    <label for="owner">Eigentümer</label>
                    <input type="text" id="owner" name="owner" v-model="owner" placeholder="Verein oder Stadt/Gemeinde">

                    <label for="email">E-Mail</label>
                    <input type="email" id="email" name="email" required v-model="mail" placeholder="Ihre E-Mail">

                    <label for="phone">Telefon</label>
                    <input type="tel" id="phone" name="phone" v-model="phone" placeholder="Ihre Telefonnummer">

                    <label for="note">Anmerkung</label>
                    <textarea id="note" name="note" v-model="note"></textarea>

                    <label class="accept">Bitte akzeptieren sie unsere <a href="#">Datenschutzbedingungen</a>.
                      <input type="checkbox" v-model="accept">
                      <span class="checkmark"></span>
                    </label>
                  </form>
                </div>
                <!-- Pflege Optionen -->
                <!--select v-show="show_pflege_extras" name="pflege" id="pflege" v-model="pflege_extras">
                  <option value="aufbuersten">Aufbürsten</option>
                  <option value="dekompaktieren">
                    Streuschicht dekompaktieren &amp; Infill nachfüllen
                  </option>
                  <option value="tiefenreinigung">Tiefenreinigung</option>
                  <option value="geraetschaften">Gerätschaften</option>
                </select-->
                <!-- Pflege Optionen ende -->

                <div class="gg_result_table">
                  <ResultTable title="Rückbau &amp; Recycling:" :price="calculation.rueckbau"/>
                  <ResultTable title="Elastikschicht:" :price="calculation.et"/>
                  <ResultTable title="Belag &amp; Infill:" :price="calculation.surface"/>
                  <ResultTable title="Zubehör:" :price="calculation.additionals"/>
                  <ResultTable title="Pflege:" :price="calculation.caretaking"/>
                  <ResultTable
                      title="Unverbindliche Gesamtsumme:"
                      :price="calculation.sum"
                  />
                  <div class="small-font">
                    * Kosten für Rückbau & Recycling der Tragschicht i. H. v. {{ toCurrency(calculation.rueckbau) }} € fallen nicht an, wenn die vorhandene Elastikschicht für den neuen Belag wiederverwendet werden kann. Eine belastbare Aussage hierzu kann erst nach einer Ortsbegehung getroffen werden.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Buttons
              prevtitle="zurück"
              nexttitle="absenden"
              @previous="previousStep"
              @next="send"
              :disabled="!(mail !== '' && name !== '' && accept)"
          />
        </div>

        <div id="gg_bg" :style="this.sources[3].background"></div>
      </div>
    </transition>

    <!-- Ende
    ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------ -->
  </div>
</template>

<script>
import Question from "./components/Question.vue";
import Imageselect from "./components/Imageselect.vue";
import Buttons from "./components/Buttons.vue";
import ResultTable from "./components/ResultTable.vue";
import Extras from "./components/Extras.vue";
import Infobox from "./components/Infobox.vue";
import GoogleMapLoader from "./components/GoogleMapLoader";
import GoogleAutosuggest from "./components/GoogleAutosuggest";
import axios from "axios";

export default {
  name: "App",
  components: {
    Question,
    Imageselect,
    Buttons,
    ResultTable,
    Extras,
    Infobox,
    GoogleMapLoader,
    GoogleAutosuggest

  },
  data() {
    return {
      loader: null,
      current: 0,
      show_pflege_extras: false,
      show_info: false,
      et_info: false,
      area: 0,
      location: {
        lat: 51.397,
        lng: 10.644
      },
      zoom: 10,
      polygons: [],


      status: "",
      adresse: "",
      baujahr: "2010",
      elastikschicht: 0,
      elastikschicht_staerke: 35,
      hersteller: 0,
      faser_laenge: 30,
      verfuellung: {
        material: 0,
        sand: 25,
        materialmenge: 5,
      },
      system: 0,
      linierung: 0,
      goals: [],
      corner_flag: false,
      pflege: false,
      pflege_extras: "aufbuersten",
      mail:"",
      name:"",
      position: "",
      verein: "",
      owner: "",
      phone: "",
      note: "",
      accept: false,
      calculation: {}
    };
  },
  computed: {
    classDisabled() {
      return {
        'disabled': this.status === 'teilsanierung'
      }
    }
  },
  methods: {
    changePlace: function (pos, place) {
      this.setLocation(pos);
      this.polygons = [];
      this.zoom = 18;
      this.adresse = place.name
    },
    updatePoints: function (points) {
      this.polygons = points.getArray();
    },
    setZoom: function (zoom) {
      this.zoom = zoom;
    },
    previousStep: function () {

      if ((this.current === 2 && this.status == "neubau") || (this.current === 3 && this.status == "pflege")) {
        this.image_select(0)
      } else {
        this.current--;
      }


    },
    toCurrency(value){
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR'
      });
      return formatter.format(value);
    },
    send() {
      let data = this.getData();
      data.email = this.mail;
      data.name = this.name;
      data.address = this.adresse;
      data.tel = this.phone;
      data.owner = this.owner;
      data.association = this.verein;
      data.function = this.position;
      data.remarks = this.note;

      axios.post(`https://rasenplatz.ggiants.com/api/calculate`,
          data
      )
          .then(response => {
            console.log(response)
          })
          .catch(e => {
            this.errors.push(e)
          })
    },
    getData(){
      let data = {
        'm2': this.area,
        'bau_jahr': this.baujahr,
        'type': this.status,
        'a': this.elastikschicht,
        'a_strength': this.elastikschicht_staerke,
        'b': this.hersteller,
        'd': this.verfuellung.material,
        'verfullung_kg': this.verfuellung.materialmenge,
        'sand_kg': this.verfuellung.sand,
        'f': this.system
      };
      if(this.goals.length > 0){
        this.goals.forEach(
            function(goal){
              data['h_' + goal] = true;
            }
        )

      }
      if(this.linierung == 1){
        data.zusatzlinierungKleinfeld = true;
      }
      if(this.pflege){
        data.pflege = true;
      }
      if(this.corner_flag){
        data.g = true;
      }

      if(this.status == 'pflege'){
        data = {
          'm2': this.area,
          'type': this.status,
          'bau_jahr': this.baujahr,
          'pflege': this.pflege

        };
      }
      return data;
    },
    calculate() {
      let vm = this;


      axios.post(`https://rasenplatz.ggiants.com/api/calculate`,
          this.getData()
      )
          .then(response => {
            // JSON responses are automatically parsed.
            this.calculation = response.data;
            /**
             * {"rueckbau":0,"et":0,"surface":0,"additionals":0,"caretaking":0,"sum":0}
             */
            vm.current = 3;
          })
          .catch(e => {
            this.errors.push(e)
          })
    },
    caretacking(){
      this.status = 'pflege';
      this.pflege = true;
      this.calculate();
    },
    nextStep: function () {
      this.current++;
      //console.log(this.status);
    },
    image_select: function (num) {
      this.current = num;
      if (num === 3) {
        this.calculate();
      }
    },
    infoEnter(e) {
      this.infotext = e.target.getAttribute("infotext");
      this.show_info = true;
    },
    infoLeave() {
      this.show_info = false;
      this.infotext = "";
    },
    getPosition(value) {
      this.position = value;
    },
    setArea(value) {
      this.area = value;
    },
    setAdresse(value) {
      this.adresse = value;
    },
    setLocation(value) {
      this.location = value;
    },
    addPoint(point) {
      this.polygons.push(point)
    },
    clearPoints() {
      this.$refs.map_loader.clear();
    },
    removeLast() {
      this.polygons.pop();
      this.$refs.map_loader.removeLast();
    },
    scrollUp() {
      document.body.scrollTo({top: 0, left: 0, behavior: "smooth"});
      document.documentElement.scrollTo({top: 0, left: 0, behavior: "smooth"});
    }
  },


  created() {
    this.sources = [
      {
        id: 1,
        title: "Ihr Sportplatz",
        question:
            "Bitte geben Sie Ihre Adresse ein und zeichnen Sie durch Klicken auf der Karte die Fläche des Feldes ein.",
        map: true,
        background: 'background-image: url("../images/floor.jpg")',
      },
      {
        id: 2,
        title: "Rückbau",
        question:
            "Geben Sie hier bitte soweit möglich an, welcher Bauart die aktuelle Spielstätte ist.",
        map: false,
        background: 'background-image: url("../images/night.jpg")',
      },
      {
        id: 3,
        title: "Rasensystem",
        question:
            "Bitte geben Sie Ihre Adresse ein und zeichnen Sie durch Klicken auf der Karte die Fläche des Feldes ein.",
        map: false,
        background: 'background-image: url("../images/floor.jpg")',
        lines: [
          {
            id: "lines-1",
            title: "Standard",
            image: "images/linierung.svg",
            name: "lines",
            type: "radio",
            value: 0,
            checked: true,
          },
          {
            id: "lines-2",
            title: "+ Zusatzlinierung Kleinfeld",
            image: "images/linierung_zusatz.svg",
            name: "lines",
            type: "radio",
            value: 1,
            checked: false,
          },
        ],
        goals: [
          {
            id: "goal-1",
            title: "Großfeld: mobil",
            image: "images/tor_mobil.svg",
            name: "goal_1",
            type: "checkbox",
            value: 1,
            checked: false,
          },
          {
            id: "goal-2",
            title: "Großfeld: fest",
            image: "images/tor.svg",
            name: "goal_2",
            type: "checkbox",
            value: 2,
            checked: false,
          },
          {
          id: "goal-3",
          title: "Jugend-/ Kleinfeldtore",
          image: "images/tor_klein.svg",
          name: "child_goals",
          type: "checkbox",
          value: 3,
          checked: this.child_goals,
        }],
        corner_flag: [{
          id: "flag",
          title: "Eckfahne mit Vereinslogo",
          image: "images/flag.svg",
          name: "corner_flag",
          type: "checkbox",
          value: true,
          checked: this.corner_flag,
        }],
        cares: [
          {
            id: "care-1",
            title: "Wartungsvertrag",
            image: "images/care.svg",
            name: "care",
            type: "checkbox",
            value: true,
            checked: this.pflege,
          }/*,
          {
            id: "care-2",
            title: "Gerätschaften",
            image: "images/tools.svg",
            name: "care",
            type: "checkbox",
            checked: false,
          },*/
        ],
      },
      {
        id: 4,
        title: "Zusammenfassung",
        question:
            "Bitte geben Sie Ihre Adresse ein und zeichnen Sie durch Klicken auf der Karte die Fläche des Feldes ein.",
        map: false,
        background: 'background-image: url("../images/field.jpg")',
      },
      {
        neubau: [
          {
            system1: [
              {
                preis: 0,
                text: "Kunstrasen, mit Sand und Kork verfüllt",
              },
            ],
            system2: [
              {
                preis: 0,
                text: "Kunstrasen, mit Sand und Kork verfüllt",
              },
            ],
            system3: [
              {
                preis: 0,
                text: "Kunstrasen, nur mit Sand verfüllt",
              },
            ],
            system4: [
              {
                preis: 0,
                text: "Kunstrasen, nur mit Sand verfüllt",
              },
            ],
          },
        ],
      },
      {
        zubehoer: [
          {
            zusatzlinierung_standart: 0,
            zusatzlinierung_kleinfeld: 0,
            tor_gross_mobil: 0,
            tor_gross_fest: 0,
            tor_klein: 0,
            wartungsvertrag: 0,
            geraetschaften: 0,
          },
        ],
      },
      {
        tragschichten: [
          {
            elastikschicht: [
              {
                name: 'Elastikschicht',
                preis: 5,
              }
            ],
            elastische_tragschicht: [
              {
                name: 'Elastische Tragschicht',
                preis: 10,
              }
            ],
            weiss_nicht: [
              {
                name: 'weiß nicht',
                preis: 0,
              }
            ],
            staerke: 20,
          },
        ],
      },
      {
        belag: [
          {
            hersteller: [
              {
                desso: 1,
                domo: 2,
                edel: 3,
                fieldturf: 4,
                juta: 5,
                mondo: 6,
                polytan: 7,
              },
            ],
            faser_laenge: 10,
          },
        ],
      },
      {
        verfuellung: [
          {
            sand: 20,
            material: [
              {
                epdm: 0,
                sbr: 0,
                gummi: 0,
              },
            ],
          },
        ],
      }
    ];
  },
};
</script>

<style>
/* * VUE ANIMATIONS */
.fade-enter-active {
  transition: all .3s ease .3s;
}

.fade-leave-active {
  transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.fade-enter, .fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */
{
  transform: translateX(10px);
  opacity: 0;
}
.gg_result_table{
  max-width: 50%;
}
</style>
