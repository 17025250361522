<template>
  <div id="gg_map_container" class="google-map" ref="googleMap"> </div>
  <Loader v-if="''" />

</template>

<script>
import {Loader} from '@googlemaps/js-api-loader'

export default {
  name: "GoogleMapLoader",
  components: {Loader},
  emits: ["input","center_changed","zoom_changed", "update_points", "add_point"],
  props: {
    mapConfig: Object,
    apiKey: String,
    location: Object,
    zoom: Number,
    points: Array
  },
  data() {
    return {
      loader: null,
      google: null,
      map: null,
      polygon: false,
      polygon_config: {
        paths: this.points,
        strokeColor: "#00f700",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#00f700",
        fillOpacity: 0.35,
        editable: true,
        polygon: null,
        area: 0
      }
    }
  },
  async mounted(){
    this.loader = new Loader({
      apiKey: this.apiKey,
      version: "weekly",
      libraries: ["places","geometry"]
    })
    //this.google = googleMapApi
    this.initializeMap()
  },
  watch: {
    location: function () {
      this.clear();
      this.initializeMap();
    }
  },
  methods:{
    clear() {
      if(this.polygon){
        this.polygon.setMap(null)
        this.polygon = false;
      }
      this.$emit("input", 0);
    },
    removeLast() {
      this.polygon.setPath(this.points);
      this.$emit("input", this.google.maps.geometry.spherical.computeArea(this.polygon.getPath()));
    },
    initializeMap() {
      const mapContainer = this.$refs.googleMap
      let vm = this;
      this.loader
          .load()
          .then((google) => {
            this.google = google;
            this.map = new google.maps.Map(mapContainer, {
              center: this.location,
              zoom: this.zoom,
              mapTypeId: google.maps.MapTypeId.HYBRID,
              streetViewControl: false,
              draggableCursor: 'auto',
              mapTypeControl: false,
              fullscreenControl: false
            });

            this.polygon = new google.maps.Polygon(this.polygon_config);
            this.polygon.setMap(this.map);

            this.map.addListener("dragend", () => {
              this.$emit("center_changed", this.map.center);
            });

            this.map.addListener("zoom_changed", () => {
              this.$emit("zoom_changed", this.map.zoom);
            });

            // Polygonevent
            google.maps.event.addListener(this.map, 'click', function (event) {

              vm.$emit("add_point", event.latLng);
              vm.polygon_config.paths = vm.points;
              if (vm.polygon) {
                vm.polygon.setPath(vm.points);
              } else {
                vm.polygon = new google.maps.Polygon(vm.polygon_config);
                vm.polygon.setMap(vm.map);
              }

              google.maps.event.addListener(vm.polygon.getPath(), 'set_at', function(){
                vm.$emit("input", google.maps.geometry.spherical.computeArea(vm.polygon.getPath()));
                vm.$emit("update_points", vm.polygon.getPath());
              });

              google.maps.event.addListener(vm.polygon.getPath(), 'insert_at', function(){
                vm.$emit("input", google.maps.geometry.spherical.computeArea(vm.polygon.getPath()));
                vm.$emit("update_points", vm.polygon.getPath())
              });
              vm.$emit("input", google.maps.geometry.spherical.computeArea(vm.polygon.getPath()));
              
            });
          })
          .catch(e => {
           console.log('error', e)
          });
    },
  }
}
</script>

<style scoped>

.google-map{
  position: absolute;
}

</style>
