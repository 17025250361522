<template>
  <div class="gg_extras">
    <div>
      <h4 class="ml-1">{{ extra_name }}</h4>
      <div class="imageselect" >
        <div class="d-ib" v-for="extra in extras" :key="extra.id">
          <input
              class="checkbox-img"
              :type="extra.type"
              :name="extra.name"
              :id="extra.id"
              v-model="content"
              :value="extra.value ?? extra.title"
              @change="checkChange"
          />

          <label class="for-checkbox-img" :for="extra.id">
            <img class="check-img" :src="extra.image" :alt="extra.title" />
            <span class="small-font text-center w-75">{{ extra.title }}</span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Extras",
  props: ['extras','extra_name','modelValue'],
  methods: {
    checkChange(){
      this.$emit('update:modelValue', this.content);
    }
  },
  data(){
    return {
      content: this.modelValue
    }
  }
};
</script>

<style scoped>
  .imageselect{
    flex-wrap: nowrap !important;
  }
  .imageselect .check-img{
    max-width: 50px;
  }
</style>
