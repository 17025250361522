<template>
    <div class="gg_question">
        <p> {{ title }}</p>
    </div>
</template>

<script>
    export default {
        name: 'Question',
        props: {
             title: String
        }
    }
</script>