<template>
  <div class="d-ib">
    <input
      class="checkbox-img"
      :type="type"
      :name="name"
      :id="id"
      :checked="checked"
      :value="value"
      :disabled="disabled"
      @change="handleInput"
    />
   
    <label class="for-checkbox-img" :for="id">
      <img class="check-img" :src="image" :alt="title" />
      <span class="small-font text-center w-75">{{ title }}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: "Imageselect",
  props: {
    title: String,
    image: String,
    id: String,
    type: String,
    checked: Boolean,
    name: String,
    value: String,
    modelValue: String,
    disabled: Boolean
  },
  methods: {
    handleInput (event) {
      this.$emit('update:modelValue', event.target.value);
    }
  }
};
</script>
